import useViewer from ':src/domains/auth/hooks/useViewer';

export type ActiveFlags = 'isabelle' | 'embedded-zoom-session' | 'timestone';

export default function useFeatureFlag(flag: ActiveFlags) {
  const { viewer } = useViewer();
  const { featureFlags } = viewer;

  return featureFlags?.includes(flag);
}
