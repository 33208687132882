import { invert } from './utils/objectUtils';
import throwWithoutBreaking from './utils/throwWithoutBreaking';

export type LookupType = { id: number; name: string };

export enum LocationEnum {
  REMOTE = 1,
  IN_PERSON = 2,
}

export const locationTypeToString: { [key in LocationEnum]: string } = {
  [LocationEnum.REMOTE]: 'Virtual',
  [LocationEnum.IN_PERSON]: 'In-person',
};

export const getLocationTypeStringFromId = (id: number) => {
  switch (id) {
    case LocationEnum.REMOTE:
      return locationTypeToString[LocationEnum.REMOTE];
    case LocationEnum.IN_PERSON:
      return locationTypeToString[LocationEnum.IN_PERSON];
    default:
      throwWithoutBreaking(new Error(`Invalid location type id: ${id}`));
      return '[Invalid location type]';
  }
};

export enum ZoomMeetingTypeEnum {
  SCHEDULED = 2,
  RECURRING_NO_FIXED_TIME = 3,
  RECURRING_WITH_FIXED_TIME = 8,
}

export enum RoleEnum {
  SUPERVISEE = 1,
  SUPERVISOR = 2,
  ORGANIZATION_ADMIN = 3,
  ANY,
}

export enum SessionLocationEnum {
  REMOTE = 1,
  IN_PERSON = 2,
}

export enum SessionChargeStatusEnum {
  INITIAL = 0,
  CHARGED = 1,
  DECLINED = 2,
  REFUNDED = 3,
  VOIDED = 4,
}

export enum SessionDispositionEnum {
  INITIAL = 0,
  COMPLETE = 4,
  CANCELLED = 5,
  RESCHEDULED = 6,
  CHARGED = 7,
}

export enum SessionTypeEnum {
  INDIVIDUAL = 2,
  DYAD = 3,
  GROUP = 4,
}

export const sessionTypeToString: { [key in SessionTypeEnum]: string } = {
  [SessionTypeEnum.INDIVIDUAL]: 'Individual session',
  [SessionTypeEnum.DYAD]: 'Dyad session',
  [SessionTypeEnum.GROUP]: 'Group session',
};

export const sessionTypeNameToId: { [key: string]: number } = {
  Individual: 1,
  Dyad: 2,
  Group: 3,
};

export enum SessionRateEnum {
  DEFAULT = 5,
  LEGACY = 2,
  PRO_BONO = 3,
}

export const roleEnumToString: { [key in RoleEnum]: string } = {
  [RoleEnum.SUPERVISEE]: 'Supervisee',
  [RoleEnum.SUPERVISOR]: 'Supervisor',
  [RoleEnum.ORGANIZATION_ADMIN]: 'Organization Admin',
  [RoleEnum.ANY]: 'Any',
};

export const SUPERVISOR_BIO_WORD_MINIMUM = 50;

export enum SupervisorProfileDispositionEnum {
  NEEDS_APPROVAL = 0,
  PUBLISHED_AVAILABLE = 2,
  HIDDEN = 4,
}

export function getSupervisorProfileDispositionStringFromId(id: number | null | undefined) {
  if (id === SupervisorProfileDispositionEnum.NEEDS_APPROVAL) {
    return 'Unpublished';
  }
  if (id === SupervisorProfileDispositionEnum.PUBLISHED_AVAILABLE) {
    return 'Published';
  }
  if (id === SupervisorProfileDispositionEnum.HIDDEN) {
    return 'Hidden';
  }
  return '';
}

export enum SupervisorBandwidthEnum {
  READY = 1,
  LIMITED = 2,
  NONE = 3,
}

export function getSupervisorBandwidthStringFromId(id: number | null | undefined) {
  if (id === SupervisorBandwidthEnum.READY) {
    return 'Ready';
  }
  if (id === SupervisorBandwidthEnum.LIMITED) {
    return 'Limited';
  }
  if (id === SupervisorBandwidthEnum.NONE) {
    return 'None';
  }
  return '';
}

export enum SupervisorDispositionFilter {
  ALL,
  PUBLISHED,
  UNPUBLISHED,
}
export const supervisorDispositionFilterToIds = {
  [SupervisorDispositionFilter.PUBLISHED]: [SupervisorProfileDispositionEnum.PUBLISHED_AVAILABLE],
  [SupervisorDispositionFilter.UNPUBLISHED]: [
    SupervisorProfileDispositionEnum.NEEDS_APPROVAL,
    SupervisorProfileDispositionEnum.HIDDEN,
  ],
  [SupervisorDispositionFilter.ALL]: [
    SupervisorProfileDispositionEnum.NEEDS_APPROVAL,
    SupervisorProfileDispositionEnum.HIDDEN,
    SupervisorProfileDispositionEnum.PUBLISHED_AVAILABLE,
  ],
};

export enum RecommendationStatusFilter {
  AWAITING_RESPONSE = 1,
  AWAITING_APPROVAL = 2,
  APPROVED = 3,
}

export enum SupervisorUnpublishReasonEnum {
  BANDWIDTH = 1,
  POOR_FIT = 2,
  NOT_OFFERING_SUPERVISION = 3,
  OTHER = 4,
  FINANCIAL = 5,
  UNRESPONSIVE = 6,
  NOT_ENOUGH_OPPORTUNITIES = 7,
  B2B_TRANSFER = 8,
  VETTING_INCOMPLETE = 9,
}

export const supervisorUnpublishReasonEnumToString: {
  [key in SupervisorUnpublishReasonEnum]: string;
} = {
  [SupervisorUnpublishReasonEnum.BANDWIDTH]: 'Bandwidth',
  [SupervisorUnpublishReasonEnum.POOR_FIT]: 'Motivo doesn’t fit their needs',
  [SupervisorUnpublishReasonEnum.NOT_OFFERING_SUPERVISION]: 'No longer offering supervision',
  [SupervisorUnpublishReasonEnum.OTHER]: 'Other',
  [SupervisorUnpublishReasonEnum.FINANCIAL]: 'Financial',
  [SupervisorUnpublishReasonEnum.UNRESPONSIVE]: 'Unresponsive',
  [SupervisorUnpublishReasonEnum.NOT_ENOUGH_OPPORTUNITIES]: 'Not enough opportunities',
  [SupervisorUnpublishReasonEnum.B2B_TRANSFER]: 'B2B transfer supervisor',
  [SupervisorUnpublishReasonEnum.VETTING_INCOMPLETE]: 'Vetting incomplete',
};

export function getSupervisorUnpublishReasonStringFromId(id: number) {
  if (id in supervisorUnpublishReasonEnumToString) {
    return supervisorUnpublishReasonEnumToString[id as SupervisorUnpublishReasonEnum];
  }

  return '';
}

export enum UserDeleteReasonEnum {
  FINANCIAL = 1,
  NO_LONGER_OFFERING_SUPERVISION = 2,
  MOTIVO_DOESNT_FIT_THEIR_NEEDS = 3,
  COMPLIANCE = 4,
  CONDUCT = 5,
  DID_NOT_PROVIDE_REASON = 6,
  PERSONAL_REASON = 7,
  OTHER = 8,
}

export const stateIdToName: { [key: number]: string } = {
  1: 'Alabama',
  2: 'Alaska',
  3: 'Arizona',
  4: 'Arkansas',
  5: 'California',
  6: 'Colorado',
  7: 'Connecticut',
  8: 'Delaware',
  9: 'Florida',
  10: 'Georgia',
  11: 'Hawaii',
  12: 'Idaho',
  13: 'Illinois',
  14: 'Indiana',
  15: 'Iowa',
  16: 'Kansas',
  17: 'Kentucky',
  18: 'Louisiana',
  19: 'Maine',
  20: 'Maryland',
  21: 'Massachusetts',
  22: 'Michigan',
  23: 'Minnesota',
  24: 'Mississippi',
  25: 'Missouri',
  26: 'Montana',
  27: 'Nebraska',
  28: 'Nevada',
  29: 'New Hampshire',
  30: 'New Jersey',
  31: 'New Mexico',
  32: 'New York',
  33: 'North Carolina',
  34: 'North Dakota',
  35: 'Ohio',
  36: 'Oklahoma',
  37: 'Oregon',
  38: 'Pennsylvania',
  39: 'Rhode Island',
  40: 'South Carolina',
  41: 'South Dakota',
  42: 'Tennessee',
  43: 'Texas',
  44: 'Utah',
  45: 'Vermont',
  46: 'Virginia',
  47: 'Washington',
  48: 'West Virginia',
  49: 'Wisconsin',
  50: 'Wyoming',
  51: 'Washington DC',
  52: 'Canada',
  53: 'International',
  54: 'National',
};

export const usStatesPlusAll = Object.entries(stateIdToName).map(([id, name]) => ({
  id: Number(id),
  name,
}));

export const usStates = usStatesPlusAll.filter(({ id }) => id <= 51);

export const usStatesPlusNational = usStatesPlusAll.filter(({ id }) => id <= 51 || id === 54);

export const stateNameToId = invert(stateIdToName);

export const stateIdToAbbreviation: { [key: number]: string } = {
  1: 'AL',
  2: 'AK',
  3: 'AZ',
  4: 'AR',
  5: 'CA',
  6: 'CO',
  7: 'CT',
  8: 'DE',
  9: 'FL',
  10: 'GA',
  11: 'HI',
  12: 'ID',
  13: 'IL',
  14: 'IN',
  15: 'IA',
  16: 'KS',
  17: 'KY',
  18: 'LA',
  19: 'ME',
  20: 'MD',
  21: 'MA',
  22: 'MI',
  23: 'MN',
  24: 'MS',
  25: 'MO',
  26: 'MT',
  27: 'NE',
  28: 'NV',
  29: 'NH',
  30: 'NJ',
  31: 'NM',
  32: 'NY',
  33: 'NC',
  34: 'ND',
  35: 'OH',
  36: 'OK',
  37: 'OR',
  38: 'PA',
  39: 'RI',
  40: 'SC',
  41: 'SD',
  42: 'TN',
  43: 'TX',
  44: 'UT',
  45: 'VT',
  46: 'VA',
  47: 'WA',
  48: 'WV',
  49: 'WI',
  50: 'WY',
  51: 'DC',
};

export enum USStateEnum {
  Alabama = 1,
  Alaska = 2,
  Arizona = 3,
  Arkansas = 4,
  California = 5,
  Colorado = 6,
  Connecticut = 7,
  Delaware = 8,
  Florida = 9,
  Georgia = 10,
  Hawaii = 11,
  Idaho = 12,
  Illinois = 13,
  Indiana = 14,
  Iowa = 15,
  Kansas = 16,
  Kentucky = 17,
  Louisiana = 18,
  Maine = 19,
  Maryland = 20,
  Massachusetts = 21,
  Michigan = 22,
  Minnesota = 23,
  Mississippi = 24,
  Missouri = 25,
  Montana = 26,
  Nebraska = 27,
  Nevada = 28,
  'New Hampshire' = 29,
  'New Jersey' = 30,
  'New Mexico' = 31,
  'New York' = 32,
  'North Carolina' = 33,
  'North Dakota' = 34,
  Ohio = 35,
  Oklahoma = 36,
  Oregon = 37,
  Pennsylvania = 38,
  'Rhode Island' = 39,
  'South Carolina' = 40,
  'South Dakota' = 41,
  Tennessee = 42,
  Texas = 43,
  Utah = 44,
  Vermont = 45,
  Virginia = 46,
  Washington = 47,
  'West Virginia' = 48,
  Wisconsin = 49,
  Wyoming = 50,
  'Washington DC' = 51,
}

export enum LicenseCategoryEnum {
  ADDICTION_COUNSELORS = 1,
  MARRIAGE_FAMILY_THERAPISTS = 2,
  PROFESSIONAL_MENTAL_HEALTH_COUNSELORS = 3,
  PSYCHOLOGISTS = 4,
  SOCIAL_WORKERS = 5,
  BEHAVIOR_ANALYSTS = 6,
}

/**
 * @deprecated
 */
export const licenseCategoryNameToId: { [key: string]: number } = {
  'Addiction Counselor': 1,
  'Marriage and Family Therapist': 2,
  'Professional/Mental Health Counselor': 3,
  'Licensed Psychologist': 4,
  'Social Worker': 5,
  'Licensed Behavior Analyst': 6,
  'Board Certified Behavior Analyst': 7,
  'Bachelor’s Level Practitioner': 8,
  'Registered Behavior Technician': 9,
  Other: 100,
};

export enum IntroRequestStatusEnum {
  WAITING_ON_SUPERVISOR = 8,
  WAITING_ON_USER = 9,
  SCHEDULED = 10,
  ACCEPTED_BY_SUPERVISOR = 11,
  ACCEPTED_BY_USER = 12,
  ACCEPTED = 13,
  DECLINED_BY_SUPERVISOR = 14,
  DECLINED_BY_USER = 15,
  EXPIRED = 16,
}

export const CLOSED_INTRO_REQUEST_STATUSES = Object.freeze([
  IntroRequestStatusEnum.DECLINED_BY_USER,
  IntroRequestStatusEnum.DECLINED_BY_SUPERVISOR,
  IntroRequestStatusEnum.ACCEPTED,
  IntroRequestStatusEnum.EXPIRED,
]);

export enum JoinReasonEnum {
  LICENSURE = 1,
  PEER_CONSULTATION = 3,
  AGENCY_PAYING_FOR_STAFF = 6,
  SUPERVISOR = 7,
}

export const joinReasonEnumToString: { [key in JoinReasonEnum]: string } = {
  [JoinReasonEnum.LICENSURE]: 'Licensure',
  [JoinReasonEnum.PEER_CONSULTATION]: 'Peer Consultation',
  [JoinReasonEnum.AGENCY_PAYING_FOR_STAFF]: 'Agency Paying For Staff',
  [JoinReasonEnum.SUPERVISOR]: 'Supervisor',
};

export enum OrganizationSuperviseeOnboardingRequestStatusEnum {
  PENDING = 0,
  APPROVED = 1,
  DECLINED = 2,
  AWAITING_MATCH = 3,
}

export const clinicalSettingIdToName: { [key: number]: string } = {
  1: 'Private practice',
  2: 'Community-based agency',
  3: 'School-based counseling',
  4: 'Non-profit organization',
  5: 'Healthcare setting',
  1000: 'Other',
};

export enum IntroRequestDeclineReasonEnum {
  NO_BANDWIDTH = 1,
  NOT_OFFERING_SUPERVISION = 2,
  NOT_GOOD_MATCH = 3,
  NOT_SEEKING_SUPERVISION = 4,
  FOUND_ANOTHER_SUPERVISOR = 5,
  SUPERVISOR_UNRESPONSIVE = 6,
  UNPROFESSIONAL = 7,
  LIMITED_AVAILABILITY = 8,
  SUPERVISEE_NOT_READY = 9,
  NO_SHOW = 10,
}

export const legacyIntroRequestDeclineReasonIdToName: { [key: number]: string } = {
  1: 'Supervisee hasn’t graduated',
  2: 'Supervisee isn’t working in an approved clinical setting',
  3: 'Supervisee lives internationally',
  4: 'Selected supervisor is not able to accept supervisee',
  5: 'Supervisee requests a license type we don’t yet have',
  6: 'Supervisee has not yet started their coursework (BCBA only)',
};

export const introRequestDeclineReasonIdToName: { [key: number]: string } = {
  1: 'No bandwidth currently',
  2: 'No longer wish to offer clinical supervision on Motivo',
  3: 'Not a good match',
  4: 'No longer seeking supervision',
  5: 'Found another supervisor',
  6: 'Supervisor was unresponsive',
  7: 'Unprofessional',
  8: 'Limited availability',
  9: 'Supervisee is not ready to receive supervision',
  10: 'Didn’t show up',
};

export const introRequestStatusIdToName: { [key: number]: string } = {
  0: 'Pending',
  1: 'Active',
  2: 'Scheduled',
  3: 'Declined',
  4: 'Completed',
  5: 'Cancelled',
  6: 'No show',
  7: 'Pending',
  8: 'Waiting on supervisor',
  9: 'Waiting on participant',
  10: 'Scheduled',
  11: 'Accepted by supervisor',
  12: 'Accepted by participant',
  13: 'Accepted',
  14: 'Declined by supervisor',
  15: 'Declined by participant',
};

export enum SupervisorApplicationStatusEnum {
  PENDING = 0,
  APPROVED = 1,
  ONBOARDING = 2,
  COMPLETED = 3,
  DECLINED = 4,
}

export const supervisorApplicationStatusIdToName: { [key: number]: string } = {
  0: 'Pending',
  1: 'Approved',
  2: 'Onboarding',
  3: 'Completed',
  4: 'Declined',
};

export enum FormQuestionTypesEnum {
  MULTIPLE_CHOICE = 1,
  FREE_TEXT = 2,
  TRUE_FALSE = 3,
  LIKERT_SCALE = 4,
}

export const formQuestionTypeIdToName: { [key: number]: string } = {
  [FormQuestionTypesEnum.MULTIPLE_CHOICE]: 'Multiple choice',
  [FormQuestionTypesEnum.FREE_TEXT]: 'Free text',
  [FormQuestionTypesEnum.TRUE_FALSE]: 'True/False',
  [FormQuestionTypesEnum.LIKERT_SCALE]: 'Likert scale',
};

export enum CourseTypeEnum {
  ON_DEMAND = 0,
  LIVE = 1,
}

export enum CourseStatusEnum {
  DRAFT = 0,
  PUBLISHED = 1,
  HIDDEN = 2,
  UNLISTED = 3,
}

export enum BillableWorkRecordTypeEnum {
  OTHER = 1,
  CHART_AUDITING = 2,
  CLIENT_ASSESSMENT = 3,
  CLINICAL_PAPERWORK = 4,
  DIRECT_THERAPY = 5,
  SUPERVISON_PAPERWORK = 6,
  REVIEWING_CLINICAL_PAPERWORK = 7,
  PAYER_CREDENTIALING = 8,
  EMPLOYER_PAID_TRAINING = 9,
  EMPLOYER_PAID_CHECK_INS = 10,
  INTERN_STUDENT_DOCUMENTATION = 11,
}

export enum AdminRoleEnum {
  SUPERADMIN = 1,
  BILLING = 2,
  COMMUNITY_OPERATIONS = 3,
  ACCOUNT_EXECUTIVE = 4,
  CONTINUING_EDUCATION = 5,
}

export enum PopulationEnum {
  CHILDREN = 1,
  TEENS = 2,
  YOUNG_ADULTS = 3,
  ADULTS = 4,
  OLDER_ADULTS = 5,
  COUPLES = 6,
  FAMILIES = 7,
}

/**
 * @deprecated query for data instead?
 */
export const populationOptions = [
  { id: PopulationEnum.CHILDREN, name: 'Children (0-12)' },
  { id: PopulationEnum.TEENS, name: 'Teens (13-18)' },
  { id: PopulationEnum.YOUNG_ADULTS, name: 'Young adults (19-29)' },
  { id: PopulationEnum.ADULTS, name: 'Adults (30-55)' },
  { id: PopulationEnum.OLDER_ADULTS, name: 'Older adults (55+)' },
  { id: PopulationEnum.COUPLES, name: 'Couples' },
  { id: PopulationEnum.FAMILIES, name: 'Families' },
];

export enum ChronosTaskStatusEnum {
  SCHEDULED = 0,
  RUNNING = 1,
  COMPLETED = 2,
  CANCELLED = 3,
  RETRYING = 4,
  FAILED = 100,
}

export enum ReferralSourceEnum {
  COLLEAGUE = 1,
  MOTIVO_SUPERVISEE = 2,
  MOTIVO_SUPERVISOR = 3,
  AGENCY = 4,
  SEARCH_ENGINE = 5,
  FACEBOOK = 6,
  LINKEDIN = 7,
  CHANNEL_PARTNER = 8,
  OTHER = 9,
}

export enum SupervisorReferralSourceEnum {
  GOOGLE_SEARCH_ENGINE = 1,
  LINKEDIN = 2,
  FACEBOOK = 3,
  INSTAGRAM = 4,
  WORD_OF_MOUTH = 5,
  PARTNER_CAPELLA_LIBERTY_AAMFT_ETC = 6,
  CONFERENCE = 7,
  OTHER = 8,
  INDEED = 9,
  COLLEAGUE_FRIEND = 10,
}

export enum organizationSizeIdEnum {
  UP_TO_9 = 1,
  UP_TO_49 = 2,
  UP_TO_199 = 3,
  UP_TO_499 = 4,
  UP_TO_999 = 5,
  UP_TO_4999 = 6,
}

export enum OrganizationTypeIdEnum {
  ARMHS_PROGRAM = 1,
  COMMUNITY_MENTAL_HEALTH_PROVIDER = 2,
  DIGITAL_VIRTUAL_MENTAL_HEALTH_COMPANY = 3,
  GROUP_PRACTICE = 4,
  TREATMENT_CENTER = 5,
  OTHER = 100,
}

export enum MatchStatusEnum {
  INACTIVE = 0,
  AWAITING_CREDIT_CARD = 1,
  AWAITING_B2B_SUPERVISOR_APPROVAL = 2,
  AWAITING_BOARD_APPROVAL = 3,
  INACTIVE_FROM_PAYMENT_DECLINE = 4,
  DECLINED = 5,
  ACTIVE = 100,
}

export enum UnmatchSuperviseeReason {
  PERSONAL = 1,
  FINANCIAL = 2,
  NOT_GOOD_FIT = 3,
  ACHIEVED_LICENSURE = 4,
  OTHER = 5,
  SCHEDULES_DO_NOT_ALIGN = 6,
  ETHICAL_PROFESSIONAL_CONCERNS = 7,
  TRANSITIONED_TO_INTERNAL_SUPERVISION = 8,
  LEFT_THE_ORGANIZATION = 9,
}

export enum SupervisorApplicationDeclineReasonEnum {
  RATES_TOO_LOW = 1,
  UNRESPONSIVE_TO_OUTREACH = 2,
  NO_BANDWIDTH = 3,
  OTHER = 4,
}

export enum OpportunityStatusEnum {
  OPEN = 0,
  MATCHED = 1,
  CLOSED = 2,
}

export enum OpportunitiesCandidateStatusEnum {
  PENDING = 0,
  REQUESTED = 1,
  DECLINED = 2,
  APPROVED = 3,
  INTERESTED = 4,
  EVALUATING = 5,
  CLOSED = 6,
}

export const OpportunitiesCandidatesEventsDescriptions: { [key: string]: string } = {
  opportunitiesCandidateViewed: 'viewed this opportunity',
  opportunitiesCandidateInterested: 'marked interest',
  opportunitiesCandidateEvaluating: 'moved into the evaluation stage',
  opportunitiesCandidateRequested: 'sent a request',
  opportunitiesCandidateDeclined: 'declined this opportunity',
  opportunitiesCandidateIntroScheduled: 'scheduled an intro call',
  opportunitiesCandidateIntroCancelled: 'cancelled an intro call',
  opportunitiesCandidateSupportRequested: 'requested support',
  opportunitiesCandidatePartiallyMatched: 'has partially matched',
  opportunitiesCandidateMatched: 'has marked this opportunity as matched',
  opportunitiesCandidateIntroCallInviteSent: 'sent an intro call invite',
  opportunitiesCandidateChatInitiated: 'initiated a chat',
  opportunitiesCandidateIntroCallScheduled: 'scheduled an intro call',
  opportunitiesCandidateIntroCallCancelled: 'cancelled an intro call',
  opportunitiesCandidateManuallyMatchedByAccountManager: 'manually matched',
};

export enum OpportunitiesCandidateDispositionEnum {
  PUBLISHED = 0,
  UNPUBLISHED = 1,
  WAITLIST = 2,
}

export enum OrganizationRoleEnum {
  ADMIN = 1,
  CLINICAL_DIRECTOR = 2,
  PROGRAM_DIRECTOR = 3,
  BILLING_MANAGER = 4,
}

export const organizationAdminRoleIdToName: { [key: number]: string } = {
  1: 'Admin',
  2: 'Clinical director',
  3: 'Program director',
  4: 'Billing manager',
};

export const roleDescriptions: { [key: number]: string } = {
  1: 'Oversees sessions, supervisees, and billing. Can create programs and add other team members.',
  2: 'Manages their programs. Can add program directors of programs they manage. Cannot see billing.',
  3: 'Manages the supervisees of their assigned program. Cannot see billing.',
  4: 'As billing manager, you have access to sessions and billing.',
};

export const tierDescriptions: { [key: number]: string } = {
  1: 'High revenue, high growth potential',
  2: 'Low revenue, high growth potential',
  3: 'High revenue, low growth potential',
  4: 'Low revenue, low growth potential',
};

export const BASE_DURATION_OPTIONS = [30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180];

export enum UserNotificationTriggerEnum {
  INTRO_CALL_REQUESTED = 1,
  INTRO_CALL_REMINDER_INACTION = 2,
  INTRO_CALL_REMINDER_1_HOUR = 3,
  INTRO_CALL_REMINDER_24_HOURS = 4,
  SESSION_REQUESTED = 5,
  SESSION_REQUEST_REMINDER_INACTION = 6,
  SESSION_BOOKED = 7,
  SESSION_REMINDER_1_HOUR = 8,
  SESSION_REMINDER_24_HOURS = 9,
  SESSION_REMINDER_48_HOURS = 10,
  UNREAD_CHAT_MESSAGE = 11,
  UNREAD_CHAT_MESSAGE_REMINDER = 12,
  OPPORTUNITY_RECEIVED = 13,
}

export enum FrequencyEnum {
  ALL_TIME = 1,
  MONTHLY = 2,
  WEEKLY = 3,
}

export enum BillableWorkRecordCapTypeEnum {
  PER_SUPERVISOR = 1,
  PER_SUPERVISEE = 2,
}

export enum IntroCallPreferences {
  NONE = 1,
  EMPLOYER_ADMIN_WITH_SUPERVISOR = 2,
  SUPERVISEE_WITH_SUPERVISOR = 3,
}

export enum ExternalSupervisorRequestStatusEnum {
  ACTIVE_SEARCH = 1,
  PASSIVE_SEARCH = 2,
  FULFILLED = 3,
  PAUSED = 4,
  WITHDRAWN_EXTERNAL = 5,
  WITHDRAWN_INTERNAL = 6,
  URGENT_SEARCH = 7,
}

export const DEFAULT_SUPERVISOR_PAYOUT_RATES_IN_CENTS = {
  individual: 5500,
  dyad: 7500,
  group: 9000,
};

export enum GrantTypeEnum {
  ANTHEM = 1,
  OPTUM = 2,
}

export const grantIdToName: { [key: number]: string } = {
  1: 'Anthem',
  2: 'Optum',
};

export const grantTypes = Object.entries(grantIdToName).map(([id, name]) => ({
  id: Number(id),
  name,
}));

export enum ActorTypeEnum {
  USER = 'user',
  ADMIN = 'admin',
  EMPLOYER_ADMIN = 'organization_admin',
  SYSTEM = 'system',
}
